<template>
    <div class="peo-contain">
        <div class="head-background">
            <span class="title">出行人信息</span>
        </div>
        <van-collapse :border="false" v-model="collapseItems">
            <template>
                <van-collapse-item v-for="(item,index) in personList" :key="index" :title="item.username+'（'+item.usercode+'）'" :name="item.tel" value="差标" value-class="collapse_value" class="auto-cell">
                <van-row class="information">
                    <van-row v-if="item.birthday">出生日期：{{item.birthday}}</van-row>
                    <van-row v-if="item.gender">
                    <span class="gender">性别</span>：
                    <span>{{item.gender}}</span>
                    </van-row>
                    <van-row v-if="item.tel">
                    <span class="gender">电话</span>：
                    <span>{{item.tel}}</span>
                    </van-row>
                    <van-row v-if="item.idCard">
                    <span class="gender">证件号</span>：
                    <span>{{item.idCard}}</span>
                    </van-row>
                    <van-row v-if="item.travelLevel">人员职级：{{item.travelLevel}}</van-row>
                    <van-row v-if="item.stand.hotelMaxPrice">住宿标准：最高可订￥{{item.stand.hotelMaxPrice}}</van-row>
                    <van-row v-if="item.stand.allowBookHighest">飞机标准：{{item.stand.allowBookHighest|aircraftLevel}}</van-row>
                    <van-row v-if="item.stand.trainSeatInfo_seatInfo_text">火车标准：{{item.stand.trainSeatInfo_seatInfo_text}}</van-row>
                </van-row>
                </van-collapse-item>
            </template>
        </van-collapse>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["personList"])
  },
  data() {
    return {
        collapseItems: [], //记录折叠
    };
  },
  created(){
    this.personList.forEach(element => {
        this.collapseItems.push(element.tel)
    });
  },
  methods: {
  },
  filters:{
    aircraftLevel(data){
      switch(data){
        case 4:
          return "最高可订 <头等舱>";
        case 3:
          return "最高可订 <公务舱>";
        case 2:
          return "最高可订 <超值经济舱>";
        case 1:
          return "最高可订 <经济舱>";
      }
    }
  }
};
</script>
<style lang="less" scoped>
.peo-contain{
    min-height: 100vh;
}

.gender {
  width: 55px;
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
}
</style>